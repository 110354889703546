import React, { FunctionComponent, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import {
  BaseInput,
  BaseForm,
  BaseFieldSet,
  BaseDropdown,
  CurrencyInput,
  BaseSelect,
  DateInput
} from "react-epharma-components";
//import DateRangeInput from "react-epharma-components/lib/components/input/date-range";

const CrudForm: FunctionComponent = (props: any) => {
  const id = React.createRef<any>();
  const chain = React.createRef<any>();
  const replacementType = React.createRef<any>();
  const corporateName = React.createRef<any>();
  const state = React.createRef<any>();
  const benefit = React.createRef<any>();
  const industry = React.createRef<any>();
  const product = React.createRef<any>();
  const presentation = React.createRef<any>();
  const sourceDiscountPercentage = React.createRef<any>();
  const discountPercentage = React.createRef<any>();
  const service = React.createRef<any>();
  const creationUsername = React.createRef<any>();
  const startDate = React.createRef<any>();
  const endDate = React.createRef<any>();

  const _this = React.createRef<BaseForm>();

  const inputRefs = [
    id,
    chain,
    replacementType,
    corporateName,
    state,
    benefit,
    industry,
    product,
    presentation,
    sourceDiscountPercentage,
    discountPercentage,
    service,
    creationUsername,
    startDate,
    endDate
  ];

  useEffect(() => {
    console.log(_this.current?.baseForm);
  }, [_this.current]);

  return (
    <BaseForm refs={inputRefs} {...props} ref={_this}>
      <BaseFieldSet legend="Desconto Diferenciado" collapsible={false}>
        <Row>
          <BaseInput
            name="id"
            ref={id}
            hidden
            identifier
          />
          <Col xs="6" sm="6" md="6" lg="6" xl="6">
            <BaseDropdown
              name="serviceId"
              label="Serviço"
              type="string"
              labelProperty="serviceName"
              valueProperty="id"
              ref={service}
              endpoint={`${window.chainService.base + window.chainService.readReplenishmentService}`}
              required
              identifier
            />
          </Col>
          <Col xs="6" sm="6" md="6" lg="6" xl="6">
            <BaseSelect
              label="Tipo de Reposição"
              name="replacementType"
              type="string"
              options={[
                { value: "D", label: "Direta" },
                { value: "I", label: "Indireta" }
              ]}
              ref={replacementType}
              required
            />
          </Col>
        </Row>
        <Row>
          <Col xs="6" sm="6" md="6" lg="6" xl="6">
            <BaseDropdown
              name="chainId"
              label="Rede"
              type="string"
              labelProperty="corporateName"
              valueProperty="id"
              ref={chain}
              endpoint={`${window.chainService.base + window.chainService.read}`}
              identifier
            />
          </Col>
          <Col xs="6" sm="6" md="6" lg="6" xl="6">
            <BaseDropdown
              name="stateId"
              label="Estado"
              type="string"
              labelProperty="name"
              valueProperty="id"
              ref={state}
              endpoint={`${window.commonService.base + window.commonService.readState}`}
              identifier
            />
          </Col>
        </Row>
        <Row>
          <Col xs="6" sm="6" md="6" lg="6" xl="6">
            <BaseDropdown
              name="benefitId"
              label="Plano (Benefício)"
              type="string"
              labelProperty="name"
              valueProperty="id"
              ref={benefit}
              endpoint={`${window.clientService.base + window.clientService.readBenefit}`}
              identifier
            />
          </Col>
          <Col xs="6" sm="6" md="6" lg="6" xl="6">
            <BaseDropdown
              name="industryId"
              label="Indústria"
              type="string"
              labelProperty="corporateName"
              valueProperty="id"
              ref={industry}
              endpoint={`${window.productService.base + window.productService.readIndustry}`}
              required
              identifier
            />
          </Col>
        </Row>
        <Row>
          <Col xs="6" sm="6" md="6" lg="6" xl="6">
            <BaseDropdown
              name="productId"
              label="Produto"
              type="string"
              labelProperty="name"
              valueProperty="id"
              ref={product}
              childField={presentation}
              childFieldProperty="productId"
              endpoint={`${window.productService.base + window.productService.read}`}
              required
              identifier
            />
          </Col>
          <Col xs="6" sm="6" md="6" lg="6" xl="6">
            <BaseDropdown
              name="presentationId"
              label="Apresentação"
              type="string"
              labelProperty="name"
              valueProperty="id"
              ref={presentation}
              endpoint={`${window.productService.base + window.productService.readPresentation}`}
              identifier
            />
          </Col>
        </Row>
        <Row>
          <Col xs="6" sm="6" md="6" lg="6" xl="6">
            <CurrencyInput
              name="sourceDiscountPercentage"
              label="% Desconto Origem"
              maskOptions={{
                prefix: "",
                suffix: "%",
                allowNegative: false,
                integerLimit: 3,
                allowDecimal: true,
              }}
              max={100}
              min={0.01}
              ref={sourceDiscountPercentage}
            />
          </Col>
          <Col xs="6" sm="6" md="6" lg="6" xl="6">
            <CurrencyInput
              name="discountPercentage"
              label="% Desconto Negociado"
              maskOptions={{
                prefix: "",
                suffix: "%",
                allowNegative: false,
                integerLimit: 3,
                allowDecimal: true,
              }}
              max={100}
              min={0.01}
              ref={discountPercentage}
              required
            />
          </Col>
        </Row>
        <Row>
          <DateInput
            name="startDate"
            label="Data Início"
            ref={startDate}
            required
          />
          <DateInput
            name="endDate"
            label="Data Fim"
            ref={endDate}
          />
        </Row>
      </BaseFieldSet>
    </BaseForm>
  );
};

export default CrudForm;