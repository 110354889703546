import React from "react";
import { BaseScreen, BaseNavTabs } from "react-epharma-components";
import DiscountScreen from "./Home/Panel";

const DifferentiatedDiscount: React.FC = props => {

    return (
        <BaseScreen title=""
        >
          <BaseNavTabs
            navs={[
              {
                key: "ReplacementDiscount",
                name:"",
                content: <DiscountScreen/>
              }
            ]}
            defaultActiveKey="ReplacementDiscount"
          />
        </BaseScreen>
    );
}

export default DifferentiatedDiscount;
