import React, { FunctionComponent } from "react";
import { HashRouter as Router, Route } from "react-router-dom";
import DifferentiatedDiscount from "./differentiated-discount";

const Routes: FunctionComponent = () => (
  <Router>
    {/* <Route exact path="/" component={()=> (<DifferentiatedDiscount/>)} /> */}
    <Route exact path="/differentiated-discount" component={()=> (<DifferentiatedDiscount/>)} />
  </Router>
);

export default Routes;
