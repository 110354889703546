import React, { FunctionComponent } from "react";
import { Row, Col } from "react-bootstrap";
import {
  BaseInput,
  BaseForm,
  BaseFieldSet,
  BaseDropdown,
  CurrencyInput,
  BaseSelect
} from "react-epharma-components";

const SearchForm: FunctionComponent = (props) => {
  const id = React.createRef<any>();
  const corporateName = React.createRef<any>();
  const replacementType = React.createRef<any>();
  const state = React.createRef<any>();
  const benefit = React.createRef<any>();
  const industry = React.createRef<any>();
  const product = React.createRef<any>();
  const presentation = React.createRef<any>();
  const sourceDiscountPercentage = React.createRef<any>();
  const discountPercentage = React.createRef<any>();
  const service = React.createRef<any>();
  const status = React.createRef<any>();

  return (
    <BaseForm
      refs={[id, service, corporateName, replacementType, state, benefit, industry, product, presentation, sourceDiscountPercentage, discountPercentage, status]} {...props} >
      <BaseFieldSet legend="Filtros">
        <Row>
          <BaseInput
            name="id"
            ref={id}
            hidden
          />
          <Col xs="6" sm="6" md="6" lg="6" xl="6">
            <BaseDropdown
              name="serviceId"
              label="Serviço"
              type="string"
              labelProperty="serviceName"
              valueProperty="id"
              ref={service}
              endpoint={`${window.chainService.base + window.chainService.readReplenishmentService}`}
            />
          </Col>
          <Col xs="6" sm="6" md="6" lg="6" xl="6">
            <BaseSelect
              label="Tipo de Reposição"
              name="replacementType"
              type="number"
              options={[
                { value: "D", label: "Direta" },
                { value: "I", label: "Indireta" }
              ]}
              ref={replacementType}
            />
          </Col>
        </Row>
        <Row>
          <Col xs="6" sm="6" md="6" lg="6" xl="6">
            <BaseDropdown
              name="chainId"
              label="Rede"
              type="string"
              labelProperty="corporateName"
              valueProperty="id"
              ref={corporateName}
              endpoint={`${window.chainService.base + window.chainService.read}`}
            />
          </Col>
          <Col xs="6" sm="6" md="6" lg="6" xl="6">
            <BaseDropdown
              name="stateId"
              label="Estado"
              type="string"
              labelProperty="name"
              valueProperty="id"
              ref={state}
              endpoint={`${window.commonService.base + window.commonService.readState}`}
            />
          </Col>
        </Row>
        <Row>
          <Col xs="6" sm="6" md="6" lg="6" xl="6">
            <BaseDropdown
              name="benefitId"
              label="Plano (Benefício)"
              type="string"
              labelProperty="name"
              valueProperty="id"
              ref={benefit}
              endpoint={`${window.clientService.base + window.clientService.readBenefit}`}
            />
          </Col>
          <Col xs="6" sm="6" md="6" lg="6" xl="6">
            <BaseDropdown
              name="industryId"
              label="Indústria"
              type="string"
              labelProperty="corporateName"
              valueProperty="id"
              ref={industry}
              endpoint={`${window.productService.base + window.productService.readIndustry}`}
            />
          </Col>
        </Row>
        <Row>
          <Col xs="6" sm="6" md="6" lg="6" xl="6">
            <BaseDropdown
              name="productId"
              label="Produto"
              type="string"
              labelProperty="name"
              valueProperty="id"
              ref={product}
              endpoint={`${window.productService.base + window.productService.read}`}
            />
          </Col>
          <Col xs="6" sm="6" md="6" lg="6" xl="6">
            <BaseDropdown
              name="presentationId"
              label="Apresentação"
              type="string"
              labelProperty="name"
              valueProperty="id"
              ref={presentation}
              endpoint={`${window.productService.base + window.productService.readPresentation}`}
            />
          </Col>
        </Row>
        <Row>
          <Col xs="6" sm="6" md="6" lg="6" xl="6">
            <CurrencyInput
              name="sourceDiscountPercentage"
              label="% Desconto Origem"
              maskOptions={{
                prefix: "",
                suffix: "%",
                allowNegative: false,
                integerLimit: 3,
                allowDecimal: true,
              }}
              max={100}
              min={0.01}
              ref={sourceDiscountPercentage}
            />
          </Col>
          <Col xs="6" sm="6" md="6" lg="6" xl="6">
            <CurrencyInput
              name="discountPercentage"
              label="% Desconto Negociado"
              maskOptions={{
                prefix: "",
                suffix: "%",
                allowNegative: false,
                integerLimit: 3,
                allowDecimal: true,
              }}
              max={100}
              min={0.01}
              ref={discountPercentage}
            />
          </Col>
        </Row>
      </BaseFieldSet>
    </BaseForm>
  );
}

export default SearchForm;