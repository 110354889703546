import React from "react";
import { ICrud, ITableColumn, BaseGrid, BaseToolbar, CNPJ_MASK, BaseScreen} from "react-epharma-components";
import CrudForm from "./Crud";
import SearchForm from "./Search";
  
export default class DifferentiatedDiscountScreen extends React.Component {
  private columns: ITableColumn[] = [
    { name: "serviceId", displayName: "Serviço", sortable: true, width: "9%", onRender: (value, model) => { return value > 0 ? `${model.serviceId} / ${model.replenishmentService.serviceName}` : `${model.serviceId} / ${model.replenishmentService.serviceName}`;},},
    { name: "replacementType", displayName: "Tipo de Reposição", sortable: true, width: "10%", onRender: (model) => { return model == `D` ? `Direta` : `Indireta`;}, },
    { name: "industryId", displayName: "Industria", sortable: true, width: "9%", onRender: (value, model) => { return value > 0 ? `${model.industry.id} / ${model.industry.corporateName}` : ``;}, },
    { name: "productId", displayName: "Produto", sortable: true, width: "9%", onRender: (value, model) => { return value > 0 ? `${model.product.id} / ${model.product.name}` : `${model.product.id} / ${model.product.name}`;}, },
    { name: "presentationId", displayName: "Apresentação", sortable: true, width: "9%", onRender: (value, model) => { return value > 0 ? `${model.presentation.id} / ${model.presentation.name}` : ``;}, },
    { name: "benefitId", displayName: "Plano", sortable: true, width: "9%", onRender: (value, model) => { return value > 0 ? `${model.benefit.id} / ${model.benefit.name}` : ``;}, },
    { name: "chainId", displayName: "Rede", sortable: true, width: "9%", onRender: (value, model) => { return value > 0 ? `${model.chainId} / ${model.chain.corporateName}` : `Todos`;}, },
    { name: "stateId", displayName: "Estado", sortable: true, width: "9%", onRender: (value, model) =>{ return value > 0 ? `${model.stateId} / ${model.state.name}` : `Todos`;}, },
    { name: "startDate", displayName: "Inicio", sortable: true, width: "9%", type: "datetime" },
    { name: "endDate", displayName: "Fim", sortable: true, width: "9%", type: "datetime" }
  ];

  private crud: ICrud = {
    form: <CrudForm />,
     create: {
     show: true,
     },
    read: {
      show: true
    },
    // log: {
    //     show: true,
    //     id: 186,
    //     keys: "id"
    // },
    update: {
      show: true,
    }
  }; 

  render() {
    return (
      <BaseScreen title="Desconto Diferenciado" description="Cadastro">
        <BaseGrid
          name="Search"          
          baseEndpoint={`${window.chainService.base + window.chainService.differentiatedDiscount}`}
          toolbar={
            <BaseToolbar
              crud={this.crud}
              search={{
                show: true,
                form: <SearchForm />,
              }}
              
            />
          }
          mode={{
            type: "automatic",
            automatic: {
              endpoint: "/read",
            },
          }}
          columns={this.columns}
          {...this.props }
        />
      </BaseScreen>
    );
  }
}